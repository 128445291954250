<template>
  <v-container>
    <h1>Import Student</h1>
    <v-alert class="mt-2" type="info" outlined text>
      The uploaded spreadsheet needs to have proper header.
      <a style="text-decoration: underline" @click="downloadTemplate"
        >Click here to download the template</a
      >
    </v-alert>
    <v-alert
      v-if="errorData.length !== 0"
      class="mb-6"
      type="warning"
      outlined
      text
    >
      Some data can't be processed:
      <ul>
        <li v-for="(error, index) in errorData" :key="index">{{ error }}</li>
      </ul>
    </v-alert>
    <v-sheet color="white" elevation="1" class="px-5 mt-4 rounded">
      <v-form ref="form" @submit.prevent="submit">
        <v-row>
          <v-col>
            <v-file-input
              v-model="users"
              accept=".csv"
              label="Select Spreadsheet"
            />
            <v-btn
              :disabled="this.users === null"
              :loading="loading"
              color="success"
              class="mt-2"
              type="submit"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Student from "@/services/student";

export default {
  name: "Student.Import",
  data() {
    return {
      valid: false,
      users: null,
      loading: false,
      errorData: []
    };
  },
  async created() {
    this.setNavbarTitle("Manage Student");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async submit() {
      try {
        this.loading = true;
        this.errorData = [];
        const formData = new FormData();
        formData.append("users", this.users);
        await Student.import(formData);
        this.$router.push({ name: "Student.Index" });
      } catch (error) {
        this.errorData = error.response.data.data;
      } finally {
        this.loading = false;
      }
    },
    downloadTemplate() {
      const element = document.createElement("a");
      const header = "email;name;phone_number;password;group_code";
      element.setAttribute("href", "data:text/csv;charset=utf-8," + header);
      element.setAttribute("download", "template.csv");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }
};
</script>
